.research {
    height: 70vw;
    background-color: black;
}

.research_container {
    font: 'Assistant', sans-serif;
    background-color: black;
    color: white;
    padding: 4%;
    height: 70vw;
}

.rea-seperator {
    position: absolute;
    right: 0px;
    align-items: center;
    width: 0;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 10.1vw solid  white;
    margin-top: -10vw;
    z-index: 1;
}

.rea-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 3%;
    padding-top: 0;
    background-color: black;
    height: 45vw;
}

.rea-left-aligned {
    text-align: left;
    line-height: 1;
    padding-left: 2vw;
}

.rea-logo {
    height: 6vw;
    width: 6vw;
    background-image: url("medtronic_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.rea-title {
    color: white;
    font-family: 'Assistant', sans-serif;
    font-size: 6vw;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.rea-subtitle {
    margin-bottom: 2rem;
    color: white;
    font-family: 'Assistant', sans-serif;
    font-size: 1vw;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.reaanded_text {
    padding-top: 2vw;
    grid-column: span 2;
    background-color: black;
    color: white;
    text-align: left;
    font-size: 1.5vw;
    line-height: 1;
}

.cleanLink {
    color: lightblue;
    text-decoration: none;
    
}
.inner-rea-box{
    display: flex;
    flex-direction: row;
    
}

.rea-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Assistant', sans-serif;
    font-size: 2.3vw;
    flex: 1;
    padding: 20px;
    text-align: center;
    text-justify: center;
    line-height: 2;
}

.rea-bottom-bord {
    border-left: 2px solid #fff;
    padding-left: 2vw;
}

@media screen and (max-width: 768px) {
    .research {
        padding-top: 50px;
        height: 750px;
    }

    .rea-box {
        padding: 10px;
    }

    .rea-logo {
        height: 150px;
        width: 150px;
    }
    .rea-text {
        flex-direction: column;
    }

    .inner-rea-box{
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        font-size: 20pt;
    }

    .rea-left-aligned {
        text-align: center;
        padding-left: 0;
    }

    .rea-subtitle {
        display: none;
    }

    .rea-title {
        font-size: 45pt;
        border-bottom: #fff 2px solid;
    }

    .reaanded_text{
        display: none !important;
    }

    .rea-bottom-bord {
        border-left: 0px solid #fff;
        padding-left: 2vw;
    }

    .rea-seperator {
        display: none;
    }
}