
.proj-section {
    padding-top: 2vw;
    background-color: white;
}

.proj-title {
    color: black;
    font-family: 'Assistant', sans-serif;
    font-size: 6vw;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.proj-subtitle {
    margin-bottom: 2rem;
    color: black;
    font-weight: bold;
    font-family: 'Assistant', sans-serif;
    font-size: 1vw;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2vw;
}

.project-card {
    width: calc(20% - 20px);
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;

    margin: 20px;
    margin-bottom: 20px;
    

    border-color: black 1px solid;
    font-family: 'Assistant', sans-serif;
    /* height: 25vw; */
}



.project-card:hover {
    box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
}

.project-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;

    /* border-bottom: black 1px solid; */
}

.project-card h3 {
    margin: 16px;
    font-size: 1.2rem;
    font-weight: bold;


    font-size: 1vw;

}

.project-card p {
    margin: 0 16px 16px;
    line-height: 1.5;
    font-size: 0.9rem;

    font-size: 1vw;
}

/* Add media queries for responsiveness */
@media screen and (max-width: 1200px) {
    .project-card {
        width: calc(30% - 20px);
    }
    .project-card h3 {
        font-size: 2vw;
    }
    
    .project-card p {
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 768px) {
    .project-card {
        width: 100%;
    }
    .project-card img {
        display: None;
    }
    .proj-title{
        font-size: 45pt;
    }
    .proj-subtitle{
        font-size: 10pt;
    }

    .project-card h3 {
        font-size: 20pt;
    }
    
    .project-card p {
        font-size: 12pt;
    }
}