/* Hero.css */
@import url('https://fonts.googleapis.com/css?family=Assistant:200');

.hero {
    display: flex;
    justify-content: space-between;
    padding: 3%;
    padding-left: 10%;
    animation: fadeIn 1s ease-out;
    height: 45vw;
    background-color: black;
}

.hero-image {
    flex: 1;
    background-image: url("hero_img.PNG");
    background-size: contain;
    background-repeat: no-repeat;
}

.hero-text {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Assistant', sans-serif;
    margin-top: 7%;
}

.hero-title {
    margin-bottom: 0.5rem;
    color: white;
    font-family: 'Assistant', sans-serif;
    font-size: 8vw;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    /* Make the animation underline effect when hover */
    position: relative;
    transition: color 0.3s;

    /* Add the underline effect */
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 2px;
        left: 0;
        background-color: #fff;
        visibility: visible;
        transform: scaleX(.3);
        transition: all 0.5s ease-in-out;
    }

    &:hover:after {
        visibility: visible;
        transform: scaleX(.6);
    }
    
}

.hero-subtitle {
    padding-top: 3%;
    color: White;
    font-size: 2vw;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
}

.seperator {
    position: absolute;
    right: 0px;
    align-items: center;
    width: 0;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 10.2vw solid  white;
    margin-top: -10vw;
    z-index: 1;
}

.icon-grid {
    position: relative;
    top: 2vw; 
    right: 8vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
    z-index: 2;
}

.icon-grid2 {
    visibility: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    gap: 20px; /* Adjust the gap between icons if needed */
    background-color: white;
    padding: 10px;

}

.icon {
    width: 2.5vw;
    height: 2.5vw;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
}


.button {
    display: inline-block;
    font-family: 'Assistant', sans-serif;
    padding: 10px 20px;
    border: 1px solid #fff;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    font-size: 1.2vw;
    margin-top: 1vw;
    
    /* border-radius: 5px; */
    transition: all 0.3s ease;
}

.button:hover {
    font-weight: bold;
    background-color: #fff;
    color: #000;
    border-color: #000;
}

@media screen and (max-width: 768px) {
    
    .button {
        display: none;
    }

    .hero {
        margin: 0;
        padding: 0;
        flex-direction: column;
        height: 600px;
    }

    .hero-image {
        order: 1;
        margin-top: 10%;
        background-position: center;
    }

    .hero-text {
        order: 2;
        margin-top: 3%;
        flex: 0;
    }

    .hero-title {
        font-size: 40pt;
    }
    .hero-subtitle {
        font-size: 15pt;
        padding-top: 1%;
        line-height: .8;
    }

    .seperator{
        display: none;
    }

    .icon-grid {
        visibility: hidden;
    }

    .icon-grid2 {
        visibility: visible;
        
    }

    .icon {
        width: 30px;
        height: 30px;
    }
    
} 