.education{
    height: 57vw;
    background-color: white;
}
.page_seperator {
    position: absolute;
    right: 0px;
    align-items: center;
    width: 0;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 10.2vw solid  black;
    margin-top: -4vw;
    z-index: 1;
}

.education_container {
    display: flex;
    justify-content: space-between;
    padding: 0% 3% 0% 3%;
    animation: fadeIn 1s ease-out;
    height: 35vw;
    background-color: white;
}

.edu_image {
    flex: 1;
    background-image: url("iribe.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center
}

.edu-text {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Assistant', sans-serif;
}

.edu-title {
    margin-bottom: 0.5rem;
    color: rgb(0, 0, 0);
    font-family: 'Assistant', sans-serif;
    font-size: 5vw;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.edu-subtitle {
    padding-top: 1%;
    color: rgb(0, 0, 0);
    font-size: 1.5vw;
    text-align: center;
    text-transform: capitalize;
    line-height: 1;
}



.edu2-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 16vw;
    padding: 3%;
    padding-top: 0;
    background-color: white;
    /* padding-bottom: 10vw; */
}

.box {
    height: 100%;
    font-family: 'Assistant', sans-serif;
    font-size: 2vw;
    flex: 1;
    padding: 20px;
    padding-top: 0%;
    text-align: center;
    text-justify: center;
    line-height: 2;
}

.separator {
    flex: 0 0 auto;
    width: 2px;
    height: 100%;
    background-color: #000;
    margin: 0 20px;
}


@media screen and (max-width: 768px) {
    .break {
        display: none;
    }

    .education{
        height: 680px;
    }

    .edu2-text {
        flex-direction: column;
        height: auto;
    }
    .box {
        font-size: 20px;
        margin: 0;
        border-top: 2px solid #000;
        width: 75%;
        line-height: 1;
        padding: 5px;
    }
    .bottom-bord {
        border-bottom: 2px solid #000;
    }

    
    .separator {
        display: none;
    }

    .education_container {
        margin: 0;
        padding: 2%;
        flex-direction: column;
        height: 500px;
    }

    .edu-image {
        order: 1;
        background-position: center;

    }

    .edu-text {
        order: 2;
        margin-top: 3%;
        flex: 0;
        margin-bottom: 3%;
    }

    .edu-title {
        font-size: 30pt;

        &:after {
            bottom: 0px;
        }
    }

    .edu-subtitle {
        font-size: 15pt;
        padding-top: 1%;
        line-height: .8;
    }

    .page_seperator {
        display: none;
    }

}