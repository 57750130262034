.footer {
    background-color: black;
    color: white;
    padding: 20px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-links {
    display: flex;
    flex-direction: column;
}

.footer-links a {
    color: white;
    text-decoration: none;
    margin-right: 20px;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-info p {
    margin: 5px 0;
}

.footer-info a {
    color: white;
    text-decoration: none;
}

.footer-info a:hover {
    text-decoration: underline;
}